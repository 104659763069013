<div class="d-none d-lg-block chat-sidebar-container">
  <div class="d-flex flex-column row-gap-3 h-100">
    <shared-button
      text="Nieuwe chat"
      faIcon="fa-solid fa-pen-to-square"
      [fullWidth]="true"
      (clicked)="onNewConversationClick()"
    ></shared-button>

    <ng-container *ngTemplateOutlet="list"></ng-container>

    @if (isFreeAccount && !isDataLoading) {
      <ng-container *ngTemplateOutlet="premiumUpgrade; context: { $implicit: 'block-main' }"></ng-container>
    }
  </div>
</div>

<div class="d-block d-lg-none">
  <div class="d-flex justify-content-between mb-3">
    <shared-button
      faIcon="fa-solid fa-pen-to-square"
      (clicked)="onNewConversationClick()"
    ></shared-button>

    <shared-button
      [style]="'secondary'"
      faIcon="fa-solid fa-bars"
      data-bs-toggle="offcanvas"
      data-bs-target="#chatSidebar"
      aria-controls="offcanvasNavbar"
    ></shared-button>
  </div>
</div>

<shared-offcanvas-sidebar [id]="'chatSidebar'">
  <div class="d-flex flex-column row-gap-3 h-100">
    <shared-button
      text="Nieuwe chat"
      faIcon="fa-solid fa-pen-to-square"
      [fullWidth]="true"
      (clicked)="onNewConversationClick()"
    ></shared-button>

    <ng-container *ngTemplateOutlet="list; context: { $implicit: true }"></ng-container>

    @if (isFreeAccount) {
      <ng-container *ngTemplateOutlet="premiumUpgrade; context: { $implicit: 'block-white' }"></ng-container>
    }
  </div>
</shared-offcanvas-sidebar>

<!-- List template -->
<ng-template
  #list
  let-useMobileLayout
>
  <div class="d-flex flex-column row-gap-3 flex-grow-1 overflow-y-auto">
    @for (group of groups; track group) {
      @if (groupedConversations[group].length) {
        <chat-list
          [title]="groupLabels[group]"
          [conversations]="groupedConversations[group]"
          [useMobileLayout]="useMobileLayout"
          (archiveConversation)="onArchiveConversation($event)"
        ></chat-list>
      }
    }
  </div>
</ng-template>

<!-- Premium upgrade template -->
<ng-template
  #premiumUpgrade
  let-classBackground
>
  <div
    class="position-sticky bottom-0"
    [ngClass]="classBackground"
  >
    <span class="d-flex justify-content-center danger-color">Je hebt nog {{ conversationsLeft }} deze maand</span>

    <shared-button
      text="Probeer premium"
      [fullWidth]="true"
      (clicked)="onTryPremiumClick()"
    ></shared-button>
  </div>
</ng-template>
