import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { finalize, Subject, takeUntil } from "rxjs";
import { ButtonComponent, OffcanvasSidebarComponent, SidebarMenuComponent } from "@app/shared/basic-components";
import { AITeacherService } from "@app/shared/services/ai-teacher.service";
import { Conversation } from "@app/shared/models/entities/ai-teacher/Conversation";
import { ChatListComponent } from "@app/ai-teacher/components/chat-list/chat-list.component";
import { Router, RouterLink, RouterLinkActive } from "@angular/router";
import { AITeacherStore } from "@app/shared/stores/ai-teacher-store.service";
import { CommonModule } from "@angular/common";
import { UserService } from "@app/shared/services/user.service";
import { User } from "@app/shared/models/entities/user/User";
import { OSRoles } from "@app/shared/enums/user-type-enum";
import { S24Roles } from "@app/shared/enums/s24-roles.enum";
import { UtilService } from "@app/shared/services/util.service";

export enum ConversationGroups {
  TODAY = "today",
  THIS_WEEK = "thisWeek",
  LATER = "later",
}

export interface ConversationGroupsMap {
  [ConversationGroups.TODAY]: Conversation[];
  [ConversationGroups.THIS_WEEK]: Conversation[];
  [ConversationGroups.LATER]: Conversation[];
}

export const FREE_ACCOUNT_CONVERSATIONS_LIMIT = 3;

@Component({
  selector: "chat-sidebar",
  standalone: true,
  templateUrl: "./chat-sidebar.component.html",
  styleUrl: "./chat-sidebar.component.scss",
  imports: [
    CommonModule,
    ButtonComponent,
    ChatListComponent,
    RouterLink,
    RouterLinkActive,
    OffcanvasSidebarComponent,
    SidebarMenuComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatSidebarComponent implements OnInit, OnDestroy {
  protected readonly groupLabels: Record<ConversationGroups, string> = {
    [ConversationGroups.TODAY]: "Vandaag",
    [ConversationGroups.THIS_WEEK]: "Deze week",
    [ConversationGroups.LATER]: "Later",
  };

  protected isDataLoading = false;
  protected totalConversations: number = 0;
  protected groupedConversations: Record<ConversationGroups, Conversation[]> = {
    [ConversationGroups.TODAY]: [],
    [ConversationGroups.THIS_WEEK]: [],
    [ConversationGroups.LATER]: [],
  };

  private user!: User;

  private readonly destroyer = new Subject<void>();

  protected get groups(): ConversationGroups[] {
    return Object.values(ConversationGroups);
  }

  protected get isFreeAccount(): boolean {
    return UtilService.isSchool24()
      ? this.user.user_extended?.s24_role === S24Roles.DemoStudentS24
      : this.user.user_extended?.role === OSRoles.DemoStudent;
  }

  protected get conversationsLeft(): number {
    const remainingConversations = FREE_ACCOUNT_CONVERSATIONS_LIMIT - this.totalConversations;
    return remainingConversations > 0 ? remainingConversations : 0;
  }

  constructor(
    private readonly router: Router,
    private readonly userService: UserService,
    private readonly aiTeacherService: AITeacherService,
    private readonly aiTeacherStore: AITeacherStore,
    private readonly cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.getUserData();
    this.getConversations();
    this.observeIncomingConversations();
  }

  ngOnDestroy(): void {
    this.destroyer.next();
    this.destroyer.complete();
  }

  protected onNewConversationClick() {
    this.router.navigate(["gesprekken"]);
  }

  protected onTryPremiumClick() {
    this.router.navigate(UtilService.isSchool24() ? ["winkel"] : ["examentrainingen"]);
  }

  protected onArchiveConversation(id: number) {
    Object.entries(this.groupedConversations).forEach(([label, items]) => {
      this.groupedConversations[label as ConversationGroups] = items.filter((item) => item.id !== id);
    });
    this.cdr.markForCheck();
  }

  private getUserData() {
    this.userService
      .getUser()
      .pipe(takeUntil(this.destroyer))
      .subscribe((user) => {
        this.user = user;
      });
  }

  private getConversations() {
    this.isDataLoading = true;

    this.aiTeacherService
      .getConversations()
      .pipe(
        takeUntil(this.destroyer),
        finalize(() => {
          this.isDataLoading = false;
          this.cdr.markForCheck();
        })
      )
      .subscribe((conversations) => {
        this.groupedConversations = this.splitConversationsByGroup(conversations);
        this.aiTeacherStore.conversations = conversations;
      });
  }

  private observeIncomingConversations() {
    this.aiTeacherStore.conversation$.subscribe((conversation) => {
      if (conversation) {
        this.totalConversations = this.totalConversations + 1;
        this.groupedConversations[ConversationGroups.TODAY] = [conversation, ...this.groupedConversations[ConversationGroups.TODAY]];
        this.aiTeacherStore.conversations = [...this.aiTeacherStore.conversations, conversation];
        this.cdr.markForCheck();
      }
    });
  }

  private splitConversationsByGroup(conversations: Conversation[]) {
    this.totalConversations = conversations.length;

    const today = new Date();
    const todayStart = new Date(today.setHours(0, 0, 0, 0));

    const weekAgo = new Date(todayStart);
    weekAgo.setDate(todayStart.getDate() - 7);

    return conversations.reduce<ConversationGroupsMap>(
      (groups, conversation) => {
        const conversationDate = new Date(conversation.created_at);

        if (conversationDate >= todayStart) {
          groups[ConversationGroups.TODAY].push(conversation);
        } else if (conversationDate >= weekAgo && conversationDate < todayStart) {
          groups[ConversationGroups.THIS_WEEK].push(conversation);
        } else {
          groups[ConversationGroups.LATER].push(conversation);
        }

        return groups;
      },
      { [ConversationGroups.TODAY]: [], [ConversationGroups.THIS_WEEK]: [], [ConversationGroups.LATER]: [] }
    );
  }
}
