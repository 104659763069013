import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { UtilService } from "@app/shared/services/util.service";

@Component({
  selector: "chat-message-limit-notification",
  standalone: true,
  templateUrl: "./chat-message-limit-notification.component.html",
  styleUrl: "./chat-message-limit-notification.component.scss",
  imports: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatMessageLimitNotificationComponent {
  @Input() protected text!: string;

  protected get link(): string {
    return UtilService.isSchool24() ? "/winkel" : "/examentrainingen";
  }
}
