<div class="d-flex justify-content-center font-12 text-center flex-wrap">
  Gebaseerd op ChatGPT. Kan fouten bevatten.&nbsp;
  <!-- <a
    class="os-text-blue text-decoration-none"
    [href]="link"
    target="_blank"
  >
    Lees meer
  </a> -->
</div>
