<div class="position-fixed d-flex flex-column chat-widget-container">
  <div class="os-block-3 p-3 flex-grow-1 d-flex flex-column row-gap-3 chat-widget-content-height">
    <div class="d-flex justify-content-between align-items-center gap-3">
      <div class="d-flex gap-3">
        @if (!showAllConversations) {
          <shared-button
            faIcon="fa-solid fa-pen-to-square"
            padding="no-padding"
            [style]="'link-blue'"
            (clicked)="onNewConversationClick()"
          ></shared-button>

          <shared-button
            text="Alle gesprekken"
            padding="no-padding"
            [style]="'link-blue'"
            (clicked)="onAllConversationsClick()"
          ></shared-button>
        }
      </div>

      <div class="d-flex justify-content-end gap-3">
        <shared-button
          faIcon="fa-solid fa-xmark"
          padding="no-padding"
          [style]="'link-red'"
          (clicked)="onCloseWidget()"
        ></shared-button>
      </div>
    </div>

    @if (selectedConversation && selectedConversation.contextTitle && !showAllConversations) {
      <div class="font-13 border br-6 px-3 py-1 text-center">
        <a
          class="os-link"
          (click)="onConversationLinkClicked()"
        >
          {{ selectedConversation.contextTitle }}
        </a>
      </div>
    }

    @if (showAllConversations) {
      <div class="d-flex flex-column row-gap-3 h-100 overflow-y-hidden">
        <div class="flex-grow-1">
          @if (conversations.length) {
            <chat-list
              [conversations]="conversations"
              [shouldNavigateOnItemClick]="false"
              (conversationChange)="onConversationChange($event)"
              [useMobileLayout]="true"
            ></chat-list>
          } @else {
            <div class="text-center">Je hebt nog geen gesprekken</div>
          }
        </div>

        <div class="d-flex flex-column row-gap-3 position-sticky bottom-0 pt-3 chat-widget-buttons-container">
          <shared-button
            text="Nieuwe chat"
            faIcon="fa-solid fa-pen-to-square"
            [fullWidth]="true"
            (clicked)="onNewConversationClick()"
          ></shared-button>

          <shared-button
            text="Alle gesprekken"
            [style]="'secondary'"
            [fullWidth]="true"
            (clicked)="navigateToConversationsOverviewPage()"
          ></shared-button>
        </div>
      </div>
    } @else {
      @if (messages.length) {
        <div class="flex-grow-1 overflow-y-auto">
          <chat-message-list
            [messages]="messages"
            [selectedConversationId]="selectedConversationId"
            [showSupportSection]="!hasExceededConversationsLimit"
          ></chat-message-list>
        </div>
      } @else {
        <div class="flex-grow-1 d-flex justify-content-center align-items-center">
          <h4 class="font-arvo os-bold d-flex justify-content-center text-center">Waar kan ik mee helpen?</h4>
        </div>
      }

      <div class="position-sticky bottom-0 mt-3">
        <div class="mb-2">
          @if (hasExceededConversationsLimit) {
            <chat-message-limit-notification
              text="Je kunt maximaal 3 gesprekken voeren met een gratis account."
            ></chat-message-limit-notification>
          } @else if (isMessagesLimitReached) {
            <chat-message-limit-notification
              text="Je kunt maximaal 10 berichten sturen op een gratis account."
            ></chat-message-limit-notification>
          } @else if (showRetryMessage) {
            <chat-retry-notification (retry)="onRetryClick()"></chat-retry-notification>
          } @else {
            <chat-input
              [isDisabled]="isDisabledSendButton"
              (sendMessage)="messages.length ? onSendMessage($event) : onCreateConversation($event)"
            ></chat-input>
          }
        </div>
        <chat-content-disclaimer></chat-content-disclaimer>
      </div>
    }
  </div>
</div>
