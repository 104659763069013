import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { Conversation } from "@app/shared/models/entities/ai-teacher/Conversation";

@Injectable({
  providedIn: "root",
})
export class AITeacherStore {
  private readonly _conversations = new BehaviorSubject<Conversation[]>([]);
  private readonly _conversation = new BehaviorSubject<Conversation | undefined>(undefined);
  readonly conversations$ = this._conversations.asObservable();
  readonly conversation$ = this._conversation.asObservable();

  public get conversations() {
    return this._conversations.getValue();
  }

  public set conversations(conversations: Conversation[]) {
    this._conversations.next(conversations);
  }

  public set conversation(conversation: Conversation) {
    this._conversation.next(conversation);
  }
}
