import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, Output } from "@angular/core";
import { filter, finalize, Subject, takeUntil } from "rxjs";
import { ButtonComponent } from "@app/shared/basic-components";
import { ChatInputComponent } from "@app/ai-teacher/components/chat-input/chat-input.component";
import { ChatListComponent } from "@app/ai-teacher/components/chat-list/chat-list.component";
import { Conversation } from "@app/shared/models/entities/ai-teacher/Conversation";
import { AITeacherService } from "@app/shared/services/ai-teacher.service";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { ChatMessageListComponent } from "@app/ai-teacher/components/chat-message-list/chat-message-list.component";
import { ChatContentDisclaimerComponent } from "@app/ai-teacher/components/chat-content-disclaimer/chat-content-disclaimer.component";
import { UtilService } from "@app/shared/services/util.service";
import { UserService } from "@app/shared/services/user.service";
import { User } from "@app/shared/models/entities/user/User";
import { HttpErrorResponse } from "@angular/common/http";
import { SnackBarService } from "@app/shared/services/utility-services/snack-bar.service";
import { ChatMessageLimitNotificationComponent } from "@app/ai-teacher/components/chat-message-limit-notification/chat-message-limit-notification.component";
import { S24Roles } from "@app/shared/enums/s24-roles.enum";
import { OSRoles } from "@app/shared/enums/user-type-enum";
import { ChatRequestDetails, FREE_ACCOUNT_MESSAGES_LIMIT } from "@app/ai-teacher/components/chat-content/chat-content.component";
import { CourseFacadeGroupMenuService } from "@app/training/course-facade-group-page/components/course-facade-group-menu/course-facade-group-menu-service";
import { CourseMaterialsTitles } from "@app/shared/enums/course-materials-titles";
import { ExamAssignmentsStoreService } from "@app/training/training-page/onlineslagen/components/exams/exam-assignments-store.service";
import { ChatRetryNotificationComponent } from "@app/ai-teacher/components/chat-retry-notification/chat-retry-notification.component";
import { Message } from "@app/shared/models/entities/ai-teacher/Message";
import { FREE_ACCOUNT_CONVERSATIONS_LIMIT } from "@app/ai-teacher/components/chat-sidebar/chat-sidebar.component";

@Component({
  selector: "chat-widget",
  standalone: true,
  templateUrl: "./chat-widget.component.html",
  styleUrl: "./chat-widget.component.scss",
  imports: [
    ButtonComponent,
    ChatListComponent,
    ChatMessageListComponent,
    ChatInputComponent,
    ChatContentDisclaimerComponent,
    ChatMessageLimitNotificationComponent,
    ChatRetryNotificationComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatWidgetComponent implements OnInit, OnDestroy {
  @Output() closeWidget = new EventEmitter<void>();

  protected showAllConversations = false;
  protected conversations: Conversation[] = [];
  protected selectedConversation?: Conversation;
  protected selectedConversationId?: number;
  protected messages: Message[] = [];
  protected isConversationsLoading: boolean | null = null;
  protected isDisabledSendButton = false;
  protected showRetryMessage = false;
  protected hasExceededConversationsLimit = false;

  private user!: User;
  private courseId?: number;
  private facadeGroupId?: number;
  private trainingId?: number;
  private examAttemptId?: number;
  private assignment?: string;
  private lastRequestDetails?: ChatRequestDetails;

  private readonly destroyer = new Subject<void>();

  protected get isFreeAccount(): boolean {
    return UtilService.isSchool24()
      ? this.user.user_extended?.s24_role === S24Roles.DemoStudentS24
      : this.user.user_extended?.role === OSRoles.DemoStudent;
  }

  protected get isConversationsLimitReached(): boolean {
    return this.isFreeAccount && this.conversations.length >= FREE_ACCOUNT_CONVERSATIONS_LIMIT;
  }

  protected get isMessagesLimitReached(): boolean {
    return this.isFreeAccount && this.messages.length >= FREE_ACCOUNT_MESSAGES_LIMIT;
  }

  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly userService: UserService,
    private readonly courseFacadeGroupMenuService: CourseFacadeGroupMenuService,
    private readonly examAssignmentsStoreService: ExamAssignmentsStoreService,
    private readonly aiTeacherService: AITeacherService,
    private readonly snackBarService: SnackBarService,
    private readonly cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.getUserData();
    this.processUrl();
    this.subscribeToRouterChanges();
    this.getConversations();
  }

  ngOnDestroy(): void {
    this.destroyer.next();
    this.destroyer.complete();
  }

  protected onConversationChange(conversation: Conversation) {
    this.selectedConversation = conversation;
    this.selectedConversationId = conversation.id;
    this.showAllConversations = false;

    this.getConversation();
  }

  protected onNewConversationClick() {
    this.showAllConversations = false;

    this.clearConversationState();
  }

  protected onAllConversationsClick() {
    this.showAllConversations = true;

    this.clearConversationState();
    this.getConversations();
  }

  protected navigateToConversationsOverviewPage() {
    this.onCloseWidget();
    this.router.navigate(["gesprekken"]);
  }

  protected onCloseWidget() {
    this.closeWidget.emit();
  }

  protected onCreateConversation(value: string) {
    this.isConversationsLoading = null;
    this.isDisabledSendButton = true;
    this.showRetryMessage = false;

    this.lastRequestDetails = { action: "create", content: value };

    if (this.isConversationsLimitReached) {
      this.hasExceededConversationsLimit = true;

      this.addMessagePair(value, false);

      return;
    }

    this.addMessagePair(value);

    this.aiTeacherService
      .createConversation({
        question: value,
        course: this.courseId,
        facade_group: this.facadeGroupId,
        training: this.trainingId,
        product_line: UtilService.isSchool24() ? "school24" : "onlineslagen",
        exam_attempt: this.examAttemptId,
        additional_context: this.getContext(),
        url: this.router.url,
      })
      .pipe(
        takeUntil(this.destroyer),
        finalize(() => {
          const [lastConversation] = this.conversations;

          this.selectedConversation = lastConversation;
          this.selectedConversationId = lastConversation.id;
        })
      )
      .subscribe({
        next: (chunk) => {
          this.isConversationsLoading === null && this.getConversations();
          this.updateLastMessageContent(chunk);
        },
        error: (error) => this.handleError(error),
        complete: () => {
          this.isDisabledSendButton = false;
          this.cdr.markForCheck();
        },
      });
  }

  private getContext() {
    if (this.assignment) {
      return (
        "Leerling stelt deze vraag terwijl hij opgave " +
        this.assignment +
        " op het scherm heeft. Het kan uiteraard zijn dat de vraag ergens anders over gaat dus houdt dat in de gaten."
      );
    } else {
      return "";
    }
  }

  protected onSendMessage(value: string) {
    if (this.selectedConversationId) {
      this.isDisabledSendButton = true;
      this.showRetryMessage = false;

      this.lastRequestDetails = { action: "send", content: value };

      this.addMessagePair(value);

      this.aiTeacherService
        .createMessage(this.selectedConversationId, {
          question: value,
          course: this.courseId,
          training: this.trainingId,
          product_line: UtilService.isSchool24() ? "school24" : "onlineslagen",
          exam_attempt: this.examAttemptId,
          additional_context: this.getContext(),
        })
        .pipe(takeUntil(this.destroyer))
        .subscribe({
          next: (chunk) => this.updateLastMessageContent(chunk),
          error: (error) => this.handleError(error),
          complete: () => {
            this.isDisabledSendButton = false;
            this.cdr.markForCheck();
          },
        });
    }
  }

  protected onRetryClick() {
    if (this.lastRequestDetails) {
      const { action, content } = this.lastRequestDetails;

      this.messages = this.messages.slice(0, -1);

      action === "create" ? this.onCreateConversation(content) : this.onSendMessage(content);
    }
  }

  private clearConversationState() {
    this.messages = [];
    this.selectedConversation = undefined;
    this.selectedConversationId = undefined;
    this.isDisabledSendButton = false;
    this.showRetryMessage = false;
    this.lastRequestDetails = undefined;
    this.hasExceededConversationsLimit = false;
  }

  private getCourseMaterialSelectedAssignment() {
    this.courseFacadeGroupMenuService.selectedMenuItemData$.pipe(takeUntil(this.destroyer)).subscribe((selectedMenuItemData) => {
      if (selectedMenuItemData) {
        const { assignmentGroupIndex, assignmentIndex, material } = selectedMenuItemData;

        if (material === CourseMaterialsTitles.Assignments) {
          this.assignment = `${(assignmentGroupIndex || 0) + 1}${String.fromCharCode((assignmentIndex || 0) + 65)}`;
        }
      }
    });
  }

  private getExamSelectedAssignment() {
    this.examAssignmentsStoreService.currentAssignment$.subscribe((currentAssignment) => {
      this.assignment = `${(currentAssignment?.index || 0) + 1}`;
    });
  }

  private addMessagePair(questionText: string, showAIMessage = true) {
    const userMessage = Message.Factory({
      sender: "user",
      content: questionText,
      created_at: new Date(),
      name: this.user.first_name,
      avatar: this.user.avatar?.url,
    });
    const aiMessage = Message.Factory({
      sender: "ai",
      content: "",
      created_at: new Date(),
      name: UtilService.getProductChipName(),
      avatar: UtilService.getProductChipAvatar(),
    });

    this.messages = [...this.messages, userMessage, ...(showAIMessage ? [aiMessage] : [])];
  }

  private updateLastMessageContent(chunk: string) {
    const lastMessageIndex = this.messages.length - 1;

    this.messages = [
      ...this.messages.slice(0, lastMessageIndex),
      { ...this.messages[lastMessageIndex], content: this.messages[lastMessageIndex].content + chunk },
    ];

    this.cdr.markForCheck();
  }

  private handleError(error: HttpErrorResponse) {
    const { status, error: responseErrorMessage } = error;

    if (status === 402) {
      this.snackBarService.showErrorMessage(responseErrorMessage);
    } else {
      this.showRetryMessage = true;
      this.messages = this.messages.slice(0, -1);

      this.cdr.markForCheck();
    }
  }

  private getUserData() {
    this.userService
      .getUser()
      .pipe(takeUntil(this.destroyer))
      .subscribe((user) => {
        this.user = user;
      });
  }

  private subscribeToRouterChanges() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this.destroyer)
      )
      .subscribe(() => {
        this.processUrl();
      });
  }

  private processUrl() {
    const urlSegments = this.router.url.split("?")[0].split("/");
    let newTrainingId = undefined;
    let newExamAttemptId = undefined;
    let newCourseId = undefined;

    if (urlSegments[1] === "vak") {
      newTrainingId = urlSegments[2] !== undefined ? +urlSegments[2] : undefined;
    }

    if (urlSegments[3] === "examens") {
      newExamAttemptId = urlSegments[4] !== undefined ? +urlSegments[4] : undefined;
    }

    if (urlSegments[3] === "onderwerpen") {
      newCourseId = urlSegments[4] !== undefined ? +urlSegments[4] : undefined;
    } else {
      const queryParams = this.activatedRoute.snapshot.queryParams;
      newCourseId = queryParams.courseId !== undefined ? +queryParams.courseId : undefined;
    }

    if (urlSegments[3] === "leren") {
      this.facadeGroupId = urlSegments[4] !== undefined ? +urlSegments[4] : undefined;
    }

    if (this.trainingId !== newTrainingId) {
      this.trainingId = newTrainingId;
      if (newTrainingId !== undefined) {
        this.clearConversationState();
      }
    }

    if (this.examAttemptId !== newExamAttemptId) {
      this.examAttemptId = newExamAttemptId;

      this.clearConversationState();
    }

    if (this.courseId !== newCourseId) {
      this.courseId = newCourseId;

      if (newCourseId !== undefined && newCourseId !== this.selectedConversation?.course?.id) {
        this.clearConversationState();
      }
    }

    console.log(this.trainingId, this.examAttemptId, this.courseId);
    this.courseId && this.getCourseMaterialSelectedAssignment();
    this.examAttemptId && this.getExamSelectedAssignment();

    this.cdr.markForCheck();
  }

  private getConversations() {
    this.isConversationsLoading = true;

    this.aiTeacherService
      .getConversations()
      .pipe(
        takeUntil(this.destroyer),
        finalize(() => {
          this.isConversationsLoading = false;
          this.cdr.markForCheck();
        })
      )
      .subscribe((conversations) => {
        this.conversations = conversations;
      });
  }

  private getConversation() {
    if (this.selectedConversationId) {
      this.aiTeacherService
        .getConversation(this.selectedConversationId)
        .pipe(takeUntil(this.destroyer))
        .subscribe((conversation) => {
          const { messages } = conversation;

          this.messages = (messages || []).map(({ sender, content, created_at }) => ({
            sender,
            content,
            created_at: new Date(created_at || ""),
            name: sender === "ai" ? UtilService.getProductChipName() : this.user.first_name,
            avatar: sender === "ai" ? UtilService.getProductChipAvatar() : this.user.avatar?.url,
          }));
          this.cdr.markForCheck();
        });
    }
  }

  protected onConversationLinkClicked() {
    // Check if url is different from current url
    if (this.router.url.split("?")[0] !== this.selectedConversation?.contextLink && this.selectedConversation?.contextLink) {
      this.router.navigateByUrl(this.selectedConversation.contextLink);
    }
  }
}
